<template>
  <div id="addQuestionView" style="width: 1000px">
    <h2 style="font-size: 30px">{{ pageTitle }}</h2>
    <a-divider />
    <a-form :model="form" label-align="left" layout="vertical">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" class="input"/>
      </a-form-item>
      <a-divider />
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" placeholder="请选择标签" allow-clear class="input"/>
      </a-form-item>
      <a-divider />
      <a-form-item field="content" label="题目内容">
        <MdEditor :value="form.content" :handle-change="onContentChange" class="editor"/>
      </a-form-item>
      <a-divider />
      <a-form-item field="answer" label="答案">
        <MdEditor :value="form.answer" :handle-change="onAnswerChange" class="editor"/>
      </a-form-item>
      <a-divider />
      <a-form-item label="判题配置" :content-flex="false" :merge-props="false">
        <div style="background-color: rgb(0, 0, 0, .3);
                    padding: 20px;
                    border-radius: 20px"
        >
          <a-space direction="vertical" style="min-width: 480px" >
            <a-form-item field="judgeConfig.timeLimit" label="时间限制">
              <a-input-number
                  v-model="form.judgeConfig.timeLimit"
                  placeholder="请输入时间限制"
                  mode="button"
                  min="0"
                  size="large"
              />
            </a-form-item>
            <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
              <a-input-number
                  v-model="form.judgeConfig.memoryLimit"
                  placeholder="请输入内存限制"
                  mode="button"
                  min="0"
                  size="large"
              />
            </a-form-item>
            <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
              <a-input-number
                  v-model="form.judgeConfig.stackLimit"
                  placeholder="请输入堆栈限制"
                  mode="button"
                  min="0"
                  size="large"
              />
            </a-form-item>
          </a-space>
        </div>
      </a-form-item>
      <a-divider />
      <a-form-item
        label="测试用例配置"
        :content-flex="false"
        :merge-props="false"
      >
        <div style="background-color: rgb(0, 0, 0, .3);
                    padding: 20px;
                    border-radius: 20px">
          <a-form-item
              v-for="(judgeCaseItem, index) of form.judgeCase"
              :key="index"
              no-style
          >
            <a-space direction="vertical" style="min-width: 640px">
              <a-form-item
                  :field="`form.judgeCase[${index}].input`"
                  :label="`输入用例-${index}`"
                  :key="index"
              >
                <a-input
                    v-model="judgeCaseItem.input"
                    placeholder="请输入测试输入用例"
                />
              </a-form-item>
              <a-form-item
                  :field="`form.judgeCase[${index}].output`"
                  :label="`输出用例-${index}`"
                  :key="index"
              >
                <a-input
                    v-model="judgeCaseItem.output"
                    placeholder="请输入测试输出用例"
                />
              </a-form-item>
            </a-space>
            <a-divider />
          </a-form-item>
        </div>
        <div style="margin-top: 32px">
          <a-space>
            <template #split>
              <a-divider direction="vertical" />
            </template>
            <a-tag v-if="false" color='arcoblue'>Tag</a-tag>
            <a-button @click="handleAdd" type="outline" status="success">
              新增测试用例
            </a-button>
            <a-button status="danger" type="outline" @click="handleDelete(index)">
              删除最后一个
            </a-button>
          </a-space>
        </div>
      </a-form-item>
      <a-divider />
      <div style="margin-top: 16px" />
      <a-form-item>
        <a-button type="primary" style="min-width: 200px" @click="doSubmit"
          >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import message from "@arco-design/web-vue/es/message";
import { useRoute, useRouter } from "vue-router";
import {Service} from "@/generated";
const router = useRouter();
const route = useRoute();
// 如果页面地址包含 update，视为更新页面
const updatePage = route.path.includes("update");
const id = route.query.id;
const pageTitle = id ? "修改题目" : "创建题目";

let form = ref({
  title: "",
  tags: [],
  answer: "",
  content: "",
  judgeConfig: {
    memoryLimit: 1000,
    stackLimit: 1000,
    timeLimit: 1000,
  },
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
});

/**
 * 根据题目 id 获取老的数据
 */
const loadData = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const res = await Service.getQuestionByIdUsingGet(
    id as any
  );
  if (res.code === 1) {
    form.value = res.data as any;
    // json 转 js 对象
    if (!form.value.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
    }
    if (!form.value.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 1000,
        stackLimit: 1000,
        timeLimit: 1000,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
    }
    if (!form.value.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags as any);
    }
  } else {
    message.error("加载失败，" + res.msg);
  }
};

onMounted(() => {
  loadData();
});

const doSubmit = async () => {
  console.log(form.value);
  // 区分更新还是创建
  if (updatePage) {
    const res = await Service.updateQuestionUsingPut(
      form.value as any
    );
    if (res.code === 1) {
      message.success("更新成功");
    } else {
      message.error("更新失败，" + res.msg);
    }
  } else {
    const res = await Service.addQuestionUsingPost(
      form.value as any
    );
    if (res.code === 1) {
      message.success("创建成功");
    } else {
      message.error("创建失败，" + res.msg);
    }
  }
  await router.replace({
    path: "/questions",
  })
};

/**
 * 新增判题用例
 */
const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};

/**
 * 删除判题用例
 */
const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};

const onContentChange = (value: string) => {
  form.value.content = value;
};

const onAnswerChange = (value: string) => {
  form.value.answer = value;
};
</script>

<style>
#addQuestionView {
  margin: 0 auto;
}

#addQuestionView .input {
  width: 80%;
}

#addQuestionView .editor {
  width: 100%;
 }

.arco-form-item-label-col > .arco-form-item-label {
  max-width: 100%;
  color: var(--color-text-2);
  white-space: normal;
  font: italic 700 20px 'Microsoft YaHei' !important;
  margin-bottom: 10px;
}

</style>
