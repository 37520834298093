<template>
  <div id="manageQuestionView">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="title" label="名称" style="min-width: 240px">
        <a-input v-model="searchParams.title" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 240px">
        <a-input-tag v-model="searchParams.tags" placeholder="请输入标签" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSubmit">提交</a-button>
      </a-form-item>
    </a-form>
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.pageNum,
        total,
      }"
      @page-change="onPageChange"
    >
      <!-- 内容 -->
      <template #content="{ record }">
        <div class="question-content">
          {{ record.content }}
        </div>
      </template>
      <!-- 答案 -->
      <template #answer="{ record }">
        <div class="answer-content">
          {{ record.answer }}
        </div>
      </template>
      <!-- 时间格式转换插槽 -->
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <!-- 按钮插槽 -->
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate(record)"> 修改</a-button>
          <a-button status="danger" @click="doDelete(record.id)">删除</a-button>
        </a-space>
      </template>
      <!-- 标签插槽 -->
      <template #tags="{ record }">
        <a-space wrap>
          <a-tag v-for="(tag, index) of record.tags" :key="index" color="green"
          >{{ tag }}
          </a-tag>
        </a-space>
      </template>
      <!-- 判题配置 -->
      <template #judgeConfig="{ record }">
        <div class="judgeConfig-content" v-if="record.judgeConfig">
          <div>{{ "memoryLimit: " + record.judgeConfig.memoryLimit }}</div>
          <div>{{ "  timeLimit: " + record.judgeConfig.timeLimit }}</div>
          <div>{{ " stackLimit: " + record.judgeConfig.stackLimit }}</div>
        </div>
        <div v-else>
          暂无配置
        </div>
      </template>
      <!-- 判题用例 -->
      <template #judgeCase="{ record }">
        <div class="judgeCase-content" v-if="record.judgeCase && record.judgeCase.length > 0">
          <div>{{ " input: " + record.judgeCase[0].input }}</div>
          <div>{{ "output: " + record.judgeCase[0].output }}</div>
          <div v-if="record.judgeCase.length > 1">...</div>
        </div>
        <div v-else>
          暂无用例
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import * as querystring from "querystring";
import { useRouter } from "vue-router";
import {Question, QuestionVO, Service} from "@/generated";
import moment from "moment";

const tableRef = ref();

const dataList:any = ref([]);
const total:any = ref(0);
const searchParams = ref({
  title: "",
  tags: [],
  pageNum: 1,
  pageSize: 8,
});

const loadData = async () => {
  const res = await Service.listQuestionByPageUsingPost(
      {...searchParams.value}
  );
  if (res.code === 1) {
    dataList.value = res.data?.records;
    total.value = res.data?.total;
    dataList.value.map((data: any) => {
      // 转换标签
      if (!data.tags) {
        data.tags = [];
      } else {
        data.tags = JSON.parse(data.tags as any);
      }
      // 转换判题配置
      data.judgeConfig = JSON.parse(data.judgeConfig as any);
      // 转换判题用例
      data.judgeCase = JSON.parse(data.judgeCase as any);
      return data;
    })
  } else {
    message.error("加载失败，" + res.msg);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    pageNum: 1,
  };
};
// {id: "1", title: "A+ D", content: "新的题目内容", tags: "["二叉树"]", answer: "新的答案", submitNum: 0,…}

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    slotName: "content",
  },
  {
    title: "标签",
    slotName: "tags",
  },
  {
    title: "答案",
    slotName: "answer",
  },
  {
    title: "提交数",
    dataIndex: "submitNum",
  },
  {
    title: "通过数",
    dataIndex: "acceptedNum",
  },
  {
    title: "判题配置",
    slotName: "judgeConfig",
  },
  {
    title: "判题用例",
    slotName: "judgeCase",
  },
  {
    title: "用户id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    pageNum: page,
  };
};

const doDelete = async (id: any) => {
  const res = await Service.deleteQuestionByIdUsingDelete(id);
  if (res.code === 1) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败");
  }
};

const router = useRouter();

const doUpdate = (question: Question) => {
  router.push({
    path: "/update/question",
    query: {
      id: question.id,
    },
  });
};
</script>

<style scoped>
#manageQuestionView {
  margin: 0 auto;
}

#manageQuestionView .question-content, .answer-content {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
</style>
