import axios from "axios";
import {useRoute, useRouter} from "vue-router";

axios.defaults.withCredentials = true; // 默认发请求提交cookie

// 添加请求拦截器
axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        console.log("我要发请求啦", config);
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        console.log("我接受到响应啦");
        console.log(response);
        return response;
    },
    function (error) {
        if (error && error.response) {
            switch (error.response.status) {
                case 401:
                    window.location.href = "/user/login";
                    break;
                case 405:
                    error.message = "请求错误";
                    break;
            }
        }
        return Promise.reject(error);
    }
);

