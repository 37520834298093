/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuestionAddDTO } from '../models/QuestionAddDTO';
import type { QuestionEditDTO } from '../models/QuestionEditDTO';
import type { QuestionQueryDTO } from '../models/QuestionQueryDTO';
import type { QuestionSubmitAddDTO } from '../models/QuestionSubmitAddDTO';
import type { QuestionSubmitQueryDTO } from '../models/QuestionSubmitQueryDTO';
import type { QuestionUpdateDTO } from '../models/QuestionUpdateDTO';
import type { Result } from '../models/Result';
import type { Result_List_UserVO_ } from '../models/Result_List_UserVO_';
import type { Result_long_ } from '../models/Result_long_';
import type { Result_PageResult_ } from '../models/Result_PageResult_';
import type { Result_Question_ } from '../models/Result_Question_';
import type { Result_QuestionVO_ } from '../models/Result_QuestionVO_';
import type { Result_UserVO_ } from '../models/Result_UserVO_';
import type { UserLoginDTO } from '../models/UserLoginDTO';
import type { UserRegisterDTO } from '../models/UserRegisterDTO';
import type { UserUpdateDTO } from '../models/UserUpdateDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 添加题目
     * @param questionAddDto questionAddDTO
     * @returns Result_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addQuestionUsingPost(
        questionAddDto: QuestionAddDTO,
    ): CancelablePromise<Result_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question',
            body: questionAddDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 出题人编辑题目
     * @param questionEditDto questionEditDTO
     * @returns Result OK
     * @returns any Created
     * @throws ApiError
     */
    public static editQuestionUsingPut(
        questionEditDto: QuestionEditDTO,
    ): CancelablePromise<Result | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/question/edit',
            body: questionEditDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 管理员获取题目列表
     * @param questionQueryDto questionQueryDTO
     * @returns Result_PageResult_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listQuestionByPageUsingPost(
        questionQueryDto: QuestionQueryDTO,
    ): CancelablePromise<Result_PageResult_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question/get',
            body: questionQueryDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 获取题目VO列表
     * @param questionQueryDto questionQueryDTO
     * @returns Result_PageResult_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listQuestionVoByPageUsingPost(
        questionQueryDto: QuestionQueryDTO,
    ): CancelablePromise<Result_PageResult_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question/get/vo',
            body: questionQueryDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 根据Id获取题目VO对象
     * @param id id
     * @returns Result_QuestionVO_ OK
     * @throws ApiError
     */
    public static getQuestionVoByIdUsingGet(
        id: number,
    ): CancelablePromise<Result_QuestionVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/question/get/vo/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 管理员或当前用户，根据Id获取题目原对象
     * @param id id
     * @returns Result_Question_ OK
     * @throws ApiError
     */
    public static getQuestionByIdUsingGet(
        id: number,
    ): CancelablePromise<Result_Question_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/question/get/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 分页获取题目提交列表
     * @param questionSubmitQueryDto questionSubmitQueryDTO
     * @returns Result_PageResult_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listQuestionSubmitVoByPageUsingPost(
        questionSubmitQueryDto: QuestionSubmitQueryDTO,
    ): CancelablePromise<Result_PageResult_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question/questionSubmit/get/vo',
            body: questionSubmitQueryDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 提交题目记录
     * @param questionSubmitAddDto questionSubmitAddDTO
     * @returns Result_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static doQuestionSubmitUsingPost(
        questionSubmitAddDto: QuestionSubmitAddDTO,
    ): CancelablePromise<Result_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question/questionSubmit/submit',
            body: questionSubmitAddDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 管理员修改题目
     * @param questionUpdateDto questionUpdateDTO
     * @returns Result OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateQuestionUsingPut(
        questionUpdateDto: QuestionUpdateDTO,
    ): CancelablePromise<Result | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/question/update',
            body: questionUpdateDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 删除题目
     * @param id id
     * @returns Result OK
     * @throws ApiError
     */
    public static deleteQuestionByIdUsingDelete(
        id: number,
    ): CancelablePromise<Result> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/question/{id}}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * 管理员查询用户列表
     * @param username username
     * @returns Result_List_UserVO_ OK
     * @throws ApiError
     */
    public static searchUsersUsingGet(
        username?: string,
    ): CancelablePromise<Result_List_UserVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user',
            query: {
                'username': username,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 修改用户信息
     * @param userUpdateDto userUpdateDTO
     * @returns Result OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateUsingPut(
        userUpdateDto: UserUpdateDTO,
    ): CancelablePromise<Result | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user',
            body: userUpdateDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 获取当前用户
     * @returns Result_UserVO_ OK
     * @throws ApiError
     */
    public static getCurrentUserUsingGet(): CancelablePromise<Result_UserVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/current',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 用户登录
     * @param userLoginDto userLoginDTO
     * @returns Result_UserVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static userLoginUsingPost(
        userLoginDto: UserLoginDTO,
    ): CancelablePromise<Result_UserVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/login',
            body: userLoginDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 用户退出登录
     * @returns Result OK
     * @returns any Created
     * @throws ApiError
     */
    public static userLogoutUsingPost(): CancelablePromise<Result | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/logout',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 用户注册
     * @param userRegisterDto userRegisterDTO
     * @returns Result_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static userRegisterUsingPost(
        userRegisterDto: UserRegisterDTO,
    ): CancelablePromise<Result_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/register',
            body: userRegisterDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * 管理员根据Id删除用户
     * @param id id
     * @returns Result OK
     * @throws ApiError
     */
    public static deleteUserUsingDelete(
        id: number,
    ): CancelablePromise<Result> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
