<template>
    <a-row id="globalHeader" align="center" :wrap="false">
        <a-col flex="auto">
            <a-menu
                mode="horizontal"
                :selected-keys="selectedKeys"
                @menu-item-click="doMenuClick"
            >
                <a-menu-item
                    key="0"
                    :style="{ padding: 0, marginRight: '38px' }"
                    disabled
                >
                    <div class="title-bar">
                        <img class="logo" src="../assets/water.svg" />
                        <div class="title">水 OJ</div>
                    </div>
                </a-menu-item>
                <a-menu-item v-for="item in visibleRoutes" :key="item.path">
                    {{ item.name }}
                </a-menu-item>
            </a-menu>
        </a-col>
      <a-col flex="100px">
        <a-link @click="logout">退出登录</a-link>
      </a-col>
        <a-col flex="80px">
            <div style="background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
                 height: 80px;
                 border-radius: 40px;
                 text-align: center;
                 line-height: 80px"
                 >
                {{ store.state.user?.loginUser?.username ?? "未登录" }}
            </div>
        </a-col>
      <a-col flex="20px">
      </a-col>
    </a-row>
</template>

<script setup lang="ts">
import { routes } from "../router/routes";
import { useRoute, useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import {Service} from "@/generated";
import message from "@arco-design/web-vue/es/message";

const router = useRouter();
// 展示在菜单的路由数组
const visibleRoutes = computed(() => {
    return routes.filter((item, index) => {
        if (item.meta?.hideInMenu) {
            return false;
        }
        // 根据权限过滤菜单
        if (
            !checkAccess(
                store.state.user.loginUser,
                item?.meta?.access as number
            )
        ) {
            return false;
        }
        return true;
    });
});

// 默认主页
const selectedKeys = ref(["/"]);

// 路由跳转后，更新选中的菜单项
router.afterEach((to, from, failure) => {
    selectedKeys.value = [to.path];
});

const store = useStore();
console.log();

const doMenuClick = (key: string) => {
    router.push({
        path: key,
    });
};

const logout = async () => {
  const res = await Service.userLogoutUsingPost();
  // 退出登录成功，跳转到登录页
  if (res.code === 1) {
    message.success('退出登录成功');
    await router.push({
      path: "/user/login",
      replace: true,
    });
    router.go(0);
  } else {
    message.error("退出登录失败，" + res.msg);
  }
}
</script>

<style scoped>
.title-bar {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.title {
    color: #444;
    margin-left: 16px;
}

.logo {
    height: 75px;
}
</style>
