import router from "@/router";
import store from "@/store";
import ACCESS_ENUM from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";
router.beforeEach(async (to, from, next) => {
    // 不许要登录的页面就不用校验登录和权限
    if (to.meta?.access == ACCESS_ENUM.NOT_LOGIN) {
        next();
        return;
    }
    let loginUser = store.state.user.loginUser;
    // 如果现在没有登录信息，自动获取登录信息，判断是否登录
    if (!loginUser || !loginUser.userRole) {
        // 加 await 成功验证登录后，再执行后续的代码
        await store.dispatch("user/getLoginUser");
        loginUser = store.state.user.loginUser;
    }
    const needAccess = (to.meta?.access as number) ?? ACCESS_ENUM.NOT_LOGIN;
    // 要跳转的页面必须要登陆
    if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
        // 如果没登陆，跳转到登录页面
        if (
            !loginUser ||
            loginUser.userRole === ACCESS_ENUM.NOT_LOGIN
        ) {
            next(`/user/login`);
            return;
        }
        // 如果已经登陆了，但是权限不足，那么跳转到无权限页面
        console.log("登陆用户信息", store.state.user.loginUser);
        if (!checkAccess(loginUser, needAccess)) {
            next("/noAuth");
            return;
        }
    }
    next();
});
