/**
 * 权限定义
 */
const ACCESS_ENUM = {
    NOT_LOGIN: -1,
    USER: 0,
    ADMIN: 1,
};

export default ACCESS_ENUM;
