<template>
  <div id="questionSubmitView">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="questionId" label="题号" style="min-width: 240px">
        <a-input-number v-model="searchParams.questionId" placeholder="请输入题号" class="input-demo" :min="1"/>
      </a-form-item>
      <a-form-item field="language" label="编程语言" style="min-width: 240px">
        <a-select
            v-model="searchParams.language"
            :style="{ width: '320px' }"
            placeholder="选择编程语言"
        >
          <a-option>java</a-option>
          <a-option>cpp</a-option>
          <a-option>go</a-option>
          <a-option>html</a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSubmit">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
        :ref="tableRef"
        :columns="columns"
        :data="dataList"
        :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.pageNum,
        total,
      }"
        @page-change="onPageChange"
    >
      <template #judgeInfo="{ record }">
        <div>{{ `Message: ${record.judgeInfo.message}` }}</div>
        <div>{{ `Memory: ${record.judgeInfo.memory ?? 0} B` }}</div>
        <div>{{ `Time: ${record.judgeInfo.time ?? 0} ms` }}</div>
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #status="{ record }">
        {{ questionSubmitStatusMapping[record.status] }}
      </template>
      <template #user="{ record }">
        {{ record.userVO.username }}
      </template>
      <template #question="{ record }">
        {{ `${record.questionVO.id } : ${record.questionVO.title}` }}
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch, watchEffect} from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";
import {Question, QuestionSubmitQueryDTO, Service} from "@/generated";

const questionSubmitStatusMapping = {
  0: "等待中",
  1: "判题中",
  2: "成功",
  3: "失败",
}

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionSubmitQueryDTO>({
  questionId: undefined,
  language: "",
  pageSize: 10,
  pageNum: 1,
});

const loadData = async () => {
  const res = await Service.listQuestionSubmitVoByPageUsingPost(
      {
        ...searchParams.value,
        sortField: "create_time",
        sortOrder: "descend",
      }
  );
  if (res.code === 1) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});
// watch([() => searchParams.value.pageSize, searchParams.value.pageNum], () => {
//   loadData();
// })

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "提交号",
    dataIndex: "id",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "判题信息",
    slotName: "judgeInfo",
  },
  {
    title: "判题状态",
    slotName: "status",
  },
  {
    title: "题目",
    slotName: "question",
  },
  {
    title: "提交者",
    slotName: "user",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    pageNum: page,
  };
};

const router = useRouter();

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    pageNum: 1,
  };
};
</script>

<style scoped>
#questionSubmitView {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
