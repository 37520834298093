<template>
  <div id="userLoginView">
    <div :style="{ display: 'flex'}" class="card-box">
      <a-card :style="{ width: '100%', height: '100%' }" title="用户登录">
        <template #extra>
          <a-link href="/user/register">注册</a-link>
        </template>
        <a-form
            label-align="left"
            auto-label-width
            :model="form"
            @submit="handleSubmit"
            class="login-form"
        >
          <a-form-item field="userAccount" label="账号">
            <a-input v-model="form.userAccount" placeholder="请输入账号" />
          </a-form-item>
          <a-form-item field="userPassword" tooltip="密码不少于 5 位" label="密码">
            <a-input-password
                v-model="form.userPassword"
                placeholder="请输入密码"
            />
          </a-form-item>
          <br><br>
          <a-form-item>
            <a-button type="primary" html-type="submit" style="width: 120px; margin-left: 23%">
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import index, { useStore } from "vuex";
import {Service, UserLoginDTO} from "@/generated";

/**
 * 表单信息
 */
const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginDTO);

const router = useRouter();
const store = useStore();

/**
 * 提交表单
 * @param data
 */
const handleSubmit = async () => {
  const res = await Service.userLoginUsingPost(form);
  // 登录成功，跳转到主页
  if (res.code === 1) {
    await store.dispatch("user/getLoginUser");
    message.success('登录成功');
    await router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登陆失败，" + res.msg);
  }
};
</script>
<style>
#userLoginView {
  margin-top: 50px;
}

#userLoginView .login-form {
  background-color: rgba(0, 0, -1, 0.3);
  width: 75%;
  height: 100px;
  margin: 0px auto;
  padding: 30px;
  /*border-radius: 20px;*/
}

#userLoginView .card-box {
  width: 35%;
  height: 300px;
  z-index: 3;
  margin: 0 auto;
  border-radius: 30px;
  background-color: white;
}

#userLoginView .arco-card-size-medium .arco-card-header {
  height: 63px!important;
  padding: 10px 16px;
}

#userLoginView .arco-card-header-title {
  font: 700 21px "Microsoft YaHei"!important;
}

#userLoginView .arco-card.arco-card-size-medium.arco-card-bordered {
  border-radius: 30px;
  border: 1px solid rgb(0, 0, 0, .5);
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
</style>
