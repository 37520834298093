<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header" style="padding-top: 20px">
        <a-space>
          <img src="../assets/water.svg" class="logo" />
          <div>(水 OJ|･ω･)？</div>
        </a-space>
      </a-layout-header>
      <a-divider />
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer" >
        <a-tooltip content="去俺滴仓库玩玩|･ω･)？" background-color="#3491FA">
          <a-button style="border-radius: 10px" @click="toMyDepository"><strong>水OJ By lishui</strong></a-button>
        </a-tooltip>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
#userLayout {
  text-align: center;
  background: url("../assets/buffer.jpg")
    0% 0% / 100% 100%;
}

#userLayout .logo {
  width: 64px;
  height: 64px;
}

#userLayout .header {
  font: 700 30px "Microsoft YaHei";
}

#userLayout .content {
  margin-bottom: 16px;
  padding: 50px;
}

#userLayout .footer {
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: white;
  border-top: 1px solid black;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
</style>
<script setup lang="ts">
const toMyDepository = () => {
  window.location.href = "https://gitee.com/lishui2003";
}
</script>
