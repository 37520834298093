// initial state
import { StoreOptions } from "vuex";
import {Service} from "../generated";
import ACCESS_ENUM from "@/access/accessEnum";

export default {
    namespaced: true,
    state: () => ({
        loginUser: {
            username: "未登录",
        },
    }),
    actions: {
        async getLoginUser({ commit, state }) {
            // 从远程请求获取登录信息
            const res = await Service.getCurrentUserUsingGet();
            if (res.code === 1) {
                commit("updateUser", res.data);
            } else {
                commit("updateUser", {
                    ...state.loginUser,
                    userRole: ACCESS_ENUM.NOT_LOGIN,
                });
            }
        },
    },
    mutations: {
        updateUser(state, payload) {
            state.loginUser = payload;
        },
    },
} as StoreOptions<any>;
