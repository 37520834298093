<template>
    <div id="basicLayout">
        <a-layout style="min-height: 100vh">
            <a-layout-header class="header">
                <GlobalHeader/>
            </a-layout-header>
            <a-layout-content class="content">
                <router-view />
            </a-layout-content>
            <a-layout-footer class="footer">
              <a-tooltip content="去俺滴仓库玩玩|･ω･)？" background-color="#3491FA">
                <a-button style="border-radius: 10px" @click="toMyDepository"><strong>水OJ By lishui</strong></a-button>
              </a-tooltip>
            </a-layout-footer>
        </a-layout>
    </div>
</template>

<script setup>
import GlobalHeader from "@/components/GlobalHeader";
const toMyDepository = () => {
  window.location.href = "https://gitee.com/lishui2003";
}
</script>

<style scoped>

#basicLayout .header {
    border: 1px solid rgb(0, 0, 0, .3);
    box-shadow: #2d2c2c 1px 1px 5px;
}

#basicLayout .content {
    background: linear-gradient(to right, #bbb, #fff);
    padding: 2.6667vw;
}

#basicLayout .footer {
    background: #efefef;
    padding: 16px;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
</style>
